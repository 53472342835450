@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@tailwind base;
@tailwind components;
@tailwind utilities;


.about-one{
    width: 40%;     
}

.about-two{
    width: 60%;
}


.btn {
    background: linear-gradient(
      92.23deg,
      #ff56f6 21.43%,
      #b936ee 50.63%,
      #3bace2 100%,
      #406aff 117.04%
    );
    box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51);
  }

.btn:hover {
background: linear-gradient(
    92.23deg,
    #406aff 21.43%,
    #3bace2 50.63%,
    #b936ee 100%,
    #ff56f6 117.04%
);
}
  
.gradient,
    .active {
        background: linear-gradient(
        92.23deg,
        #406aff 21.43%,
        #3bace2 50.63%,
        #b936ee 100%,
        #ff56f6 117.04%
    );
}

/* .service-bg-css{
    bak
} */

.max-small{
    max-width: 800px;
}

.elements{
    animation:move 4s linear infinite;
}

.banner-element{
    animation:updown 3s linear infinite;
}

@keyframes updown{
    0%{
        transform:translate(0,15px);
    }
    25%{
        transform:translate(0px,0px);
    }
    50%{
        transform: translate(0,-15px);
    }
    75%{
        transform: translate(0px,0px);
    }
    100%{
        transform:translate(0,15px);
    }
}
/* .boo {
    position: relative;
    width: 20em; min-height: 10em;
    background: rgba(0,0,0,0) url(http://placekitten.com/320/160);
    transition: background-color 1s;
  } */

@keyframes move{
    0%{
        transform:translate(15px,0px);
    }
    25%{
        transform: translate(0px,0px);
    }
    50%{
        transform:translate(00px,15px);
    }
    75%{
        transform: translate(15px,15px);
    }
    100%{
        transform:translate(15px,0px);
    }
}

@layer base{
    body{
        @apply font-secondary text-lg leading-8 text-white
    }
    .h2 {
        @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
      }
      .h3 {
        @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
      }
    .active{
        @apply bg-blue-700 rounded-full
    }
    .section {
        @apply py-8 lg:py-24 lg:h-screen flex items-center;
    }
    .btn-sm {
        @apply h-[48px] px-6 text-sm rounded-full;
    }
    .btn-link {
        @apply text-base;
      }
      .text-gradient {
        @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#FF56F6] font-bold font-primary hover:from-[#FF56F6] hover:to-[#42A6E3];
      }
}